import TechPanel from "~/components/TechPanel";
import { Link as ScrollLink } from "react-scroll";
import { useState } from "react";
import clsx from "clsx";

const techUsed = [
    { name: "Rust", img: "/img/tech/rust.png", backing: true },
    { name: "JavaScript*", img: "/img/tech/js.png" },
    { name: "React*", img: "/img/tech/react.png", backing: true },
    { name: "TypeScript*", img: "/img/tech/typescript.png" },
    { name: "Remix*", img: "/img/tech/remix.png" },
    { name: "Tailwind CSS*", img: "/img/tech/tailwind.png" },
    { name: "Vue", img: "/img/tech/vue.png", backing: true },
    { name: "Cloudflare*", img: "/img/tech/cloudflare.jpeg" },
    { name: "Cloudflare Workers*", img: "/img/tech/cloudflare-workers.jpeg", backing: true },
    { name: "AWS", img: "/img/tech/aws.png", backing: true },
    { name: "Docker", img: "/img/tech/docker.png", backing: true },
    { name: "Python", img: "/img/tech/python.png" },
    { name: "Django", img: "/img/tech/django.png" },
    { name: "PHP", img: "/img/tech/php.png", backing: true },
    { name: "Elm", img: "/img/tech/elm.png" },
    { name: "Go", img: "/img/tech/go.png", backing: true },
    { name: "Haskell", img: "/img/tech/haskell.png", backing: true },
    { name: "Node.js", img: "/img/tech/node.png", backing: true },
    { name: "PostgreSQL", img: "/img/tech/postgres.png", backing: true },
    { name: "MongoDB", img: "/img/tech/mongodb.png" },
];

export default function Index() {
    const [historyActive, setHistoryActive] = useState(false);

    return (
        <div className="text-center h-[500vh]">
            <nav className="sticky flex flex-row justify-center top-0 bg-slate-800 p-2 border-b-slate-600 border-b-2 z-10">
                <ScrollLink
                    spy
                    smooth
                    to="head"
                    activeClass="border-b-slate-400 border-b-2"
                    className="mx-4 cursor-pointer"
                >
                    Home
                </ScrollLink>
                <ScrollLink
                    spy
                    smooth
                    to="history"
                    activeClass="border-b-slate-400 border-b-2"
                    className="mx-4 cursor-pointer"
                    onSetActive={() => setHistoryActive(true)}
                >
                    Work History
                </ScrollLink>
            </nav>
            <div id="head" className="pt-20 h-[100vh]">
                <h1 className="text-8xl">Spencer Davies</h1>
                <h3 className="text-xl mt-4">
                    Making the world a better place
                    <span className="tooltip-container">
                        *<div className="tooltip">Not guaranteed</div>
                    </span>{" "}
                    through clean code
                </h3>
                <p className="mt-48 text-lg">Some of the technologies I've used include:</p>
                <div className="w-[60vw] overflow-auto flex flex-row m-auto custom-scroll-vertical">
                    {techUsed.map((tu) => (
                        <div className="techPanel" key={tu.name}>
                            <TechPanel {...tu} />
                        </div>
                    ))}
                </div>
                <p>*Used for this site</p>
            </div>
            <div
                id="history"
                className={clsx("h-[100vh] pt-32 transition-transform duration-700 ease-out relative", {
                    "translate-x-[100vw]": !historyActive,
                })}
            >
                <h2 className="text-6xl">Work History</h2>
                <hr className="my-10 w-[60vw] m-auto" />
                <div className="my-12">
                    <h3 className="text-2xl">LiveView Technologies</h3>
                    <em>Nov 2016 - Present</em>
                    <ul>
                        <li>Serve as technical lead & architect on agile, project focused teams</li>
                        <li>Responsible for code reviews, deployment pipelines, web architecture</li>
                    </ul>
                </div>
                <div className="my-12">
                    <h3 className="text-2xl">Izeni</h3>
                    <em>Dec 2015 - Nov 2016</em>
                    <ul>
                        <li>Web development on various contracted projects</li>
                        <li>Communicated with clients to plan features and resolve bugs</li>
                    </ul>
                </div>
                <div className="my-12">
                    <h3 className="text-2xl">DevMountain</h3>
                    <em>Sep 2015 - Apr 2017</em>
                    <ul>
                        <li>Mentored new developers on web development concepts and best practices</li>
                        <li>Maintained and created new features for internal software</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
