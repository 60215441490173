interface TechPanelProps {
    name: string;
    img: string;
    backing?: boolean;
}

const TechPanel: React.FC<TechPanelProps> = ({ name, img, backing }) => {
    return (
        <div className="p-4 text-center">
            {backing ? (
                <div className="bg-slate-200 h-32 w-32 rounded-lg p-1">
                    <img src={img} alt={name} className="max-w-[100%] max-h-32 relative top-[50%] translate-y-[-50%]" />
                </div>
            ) : (
                <img src={img} alt={name} className="w-32 max-h-32 max-w-none rounded-lg" />
            )}
            <p>{name}</p>
        </div>
    );
};

export default TechPanel;
